.swiper-button-prev,
.swiper-container-rtl .swiper-button-next,
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    background-image: none;
}

.testimonials {
    background: $background__bg;
    padding: 116px 0 0;
    overflow: hidden;

    .scroll-circle {
        bottom: 35%;
        right: -133px;
    }
}

.testimonial-wrapper {
    padding: 0 100px 160px;
    position: relative;
    z-index: 22;

    .shape-shadow {
        border-radius: 10px;
        background-color: rgb(255, 255, 255);
        opacity: 0.4;
        box-shadow: 0px 60px 100px 0px rgba(102, 74, 74, 0.2);
        height: 100px;
        max-width: 54%;
        position: absolute;
        bottom: 120px;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
    }

    .slider-nav {

        .swiper-button-next,
        .swiper-button-prev {
            color: #bdb4b4;
            font-size: 36px;
            transition: all 0.3s ease-in-out;
            outline: 0;


            &:hover {
                color: $color__theme;
            }
        }

        .swiper-button-next {
            right: 20px;
        }

        .swiper-button-prev {
            left: 20px;
        }
    }
}

#testimonial {
    border-radius: 10px;
    max-width: 770px;
    margin: 0 auto;   
    position: relative;
    box-shadow: 0px 60px 100px 0px rgba(79, 35, 35, 0.1);
    background: #fff;
    width: 100%;

    .testimonial {
        text-align: center;
        margin: 0 auto;
        padding: 60px;
        position: relative;

        .testimonial-content {
            max-width: 630px;
            margin: 0 auto 30px;

            p {
                color: #656177;
                font-size: 20px;
                margin: 0;
                line-height: 38px;
            }
        }

        .single-bio-thumb {
            max-width: 80px;
            margin: 0 auto 41px;
            box-shadow: 0px 20px 30px 0px rgba(79, 35, 35, 0.2);
            border-radius: 50%;

            img {
                border-radius: 50%;
            }
        }

        .bio-info {
            .name {
                font-size: 20px;
                line-height: 32px;
                margin: 0;
            }

        }
    }
}

.testimonials-two {
    position: relative;
    overflow: hidden;

    .section-title {
        margin-bottom: 50px;
    }

    .animate-shape {
        position: absolute;
        right: -9%;
        top: -37%;
    }
}

#testimonial-wrapper {
    position: relative;
    z-index: 2;

    .slider-nav {

        .swiper-button-prev,
        .swiper-button-next {
            outline: 0;

            i {
                font-size: 32px;
            }

            &:hover {
                color: $color__theme-2
            }
        }

        .swiper-button-next {
            right: -100px;
        }

        .swiper-button-prev {
            left: -100px;
        }
    }
}

.testimonials-two,
.testimonials-two-about {

    padding: 116px 0 60px;

    .swiper-container {
        padding: 0 20px 100px;
    }
}

.testimonials-two {
    background: $background__2;
}

.testimonial-two {
    .testi-content-inner {
        overflow: hidden;
        position: relative;
        background: #fff;
        box-shadow: 0px 30px 20px 0px rgba(43, 35, 79, 0.05);
        border-radius: 10px;
        padding: 50px 60px;
    }

    .testimonial-bio {
        display: flex;
        align-items: center;
        margin-bottom: 43px;

        .avatar {
            height: 70px;
            width: 70px;
            border-radius: 50%;
            margin-right: 30px;

            img {
                border-radius: 50%;
            }
        }

        .bio-info {
            .name {
                font-size: 20px;
                font-weight: 600;
                margin-top: 0;
                margin-bottom: 5px;
            }
        }
    }

    .testimonial-content {
        margin-bottom: 35px;

        p {
            font-size: 18px;
            line-height: 30px;
            margin: 0;

        }
    }

    .shape-shadow {
        border-radius: 10px;
        background-color: rgb(255, 255, 255);
        opacity: 0.4;
        box-shadow: 0px 20px 50px 0px rgba(102, 74, 74, 0.2);
        height: 100px;
        max-width: 81%;
        position: absolute;
        bottom: -40px;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
    }

    .quote {
        position: absolute;
        right: 60px;
    }
}


.rating {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        display: inline-block;
        font-size: 14px;
        color: #fdae04;
    }
}

#testimonial-wrapper-three {
    max-width: 1060px;
    margin: 0 auto;
    position: relative;

    .quote {
        position: absolute;
        bottom: 80px;
        right: 80px;
        z-index: 22;
    }

    .swiper-container {
        padding: 0 45px 80px;
    }

    .slider-nav {
        width: 90px;
        top: auto;
        bottom: 100px;
        left: 50%;
        height: 40px;

        .swiper-button-prev {
            height: 25px;
            transition: all 0.3s ease-in-out;
            outline: 0;

            .arrow {
                margin-top: 12px;
                width: 25px;
                height: 2px;
                background: #bdb4b4;
                transition: all 0.3s ease-in-out;

                &:after {
                    content: '';
                    position: absolute;
                    width: 15px;
                    height: 2px;
                    top: 7px;
                    left: -2px;
                    background-color: #bdb4b4;
                    transform: rotate(-45deg);
                    transition: all 0.3s ease-in-out;
                }

                &:before {
                    content: '';
                    position: absolute;
                    width: 15px;
                    height: 2px;
                    top: 17px;
                    left: -2px;
                    background-color: #bdb4b4;
                    transform: rotate(45deg);
                    transition: all 0.3s ease-in-out;
                }


            }

            &:hover {
                width: 40px;
                left: -15px;

                .arrow {
                    width: 40px;
                    background: $color__theme;

                    &:before,
                    &:after {
                        background: $color__theme;
                    }
                }
            }


        }

        .swiper-button-next {
            height: 25px;
            transition: all 0.3s ease-in-out;

            .arrow {
                margin-top: 12px;
                width: 25px;
                height: 2px;
                background: #bdb4b4;
                transition: all 0.3s ease-in-out;

                &:after {
                    content: '';
                    position: absolute;
                    width: 15px;
                    height: 2px;
                    top: 7px;
                    right: -2px;
                    background-color: #bdb4b4;
                    transform: rotate(45deg);
                    transition: all 0.3s ease-in-out;
                }

                &:before {
                    content: '';
                    position: absolute;
                    width: 15px;
                    height: 2px;
                    top: 17px;
                    right: -2px;
                    background-color: #bdb4b4;
                    transform: rotate(-45deg);
                    transition: all 0.3s ease-in-out;
                }


            }

            &:hover {
                width: 40px;
                right: -15px;

                .arrow {
                    width: 40px;
                    background: $color__theme;

                    &:before,
                    &:after {
                        background: $color__theme;
                    }
                }
            }


        }
    }
}

.testimonials-two-about {
    background: $background__bg;
    padding: 116px 0 58px;
    overflow: hidden;

    .testimonial-two {

        .testi-content-inner {
            box-shadow: 0px 30px 20px 0px rgba(79, 35, 35, 0.1);
        }

        .shape-shadow {
            box-shadow: 0px 20px 50px 0px rgba(79, 35, 35, 0.1);
        }
    }

    .scroll-circle {
        bottom: 33%;
        z-index: 1;
        right: -7%;
    }

    #testimonial-wrapper .slider-nav .swiper-button-prev:hover,
    #testimonial-wrapper .slider-nav .swiper-button-next:hover {
        color: $color__theme;
    }
}



.testimonial-three {
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 20px 40px 0px rgba(79, 35, 35, 0.1);
    display: flex;
    flex-wrap: wrap;
    align-items: center;


    .avatar,
    .testimonial-content {
        flex: 1;
    }

    .testimonial-content {
        padding: 0 60px;
        margin-top: -47px;

        .bio-info {
            margin-bottom: 30px;

            .name {
                font-size: 20px;
                font-weight: 600;
            }
        }

        p {
            font-size: 18px;
            line-height: 32px;
            color: #656177;

        }
    }
}

.testimonials-three {
    padding: 116px 0 40px;
}

@media (max-width: 1440px) {
    .testimonials-two, .testimonials-two-about  {
        .animate-shape, .scroll-circle {
            max-width: 600px;
        }
    }

    
}
@media (max-width: 1200px) {
    #testimonial-wrapper-three {
        .slider-nav {
            bottom: 70px;
        }

    }
}
@media (max-width: 1024px) {
    .testimonial-three {
        .testimonial-content {
            padding: 0 20px;
            margin-top: 0;

            p {
                font-size: 16px;
                line-height: 28px;
            }

            .bio-info {
                margin-bottom: 10px;
            }
        }
    }

    #testimonial-wrapper-three {
        .slider-nav {
            bottom: 80px;
        }

        .quote {
            width: 80px;
        }
    }

    .testimonials {
        .scroll-circle {
            max-width: 500px;
        }
    }

    .testimonials-two-about {
        .scroll-circle {
            max-width: 400px;
        }
    }
}

@media (max-width: 991px) {
    .testimonials {
        padding: 76px 0 10px;
    }

    .testimonials-two .swiper-container,
    .testimonials-two-about .swiper-container {
        padding: 0 20px 80px;
    }

    .testimonials-two, .testimonials-two-about {
        padding: 76px 0 30px;
    }

    #testimonial-wrapper {
        .slider-nav {
            .swiper-button-prev {
                left: -30px;      
            }

            .swiper-button-next {
                right: -20px;      
            }
        }
    }

    .testimonials-three {
        padding: 76px 0 0;
    }

    #testimonial-wrapper-three {
        .slider-nav {
            display: none;
        }
    }

    .testimonials-two {
        .animate-shape {
            right: -80%;
        }
    }

    .testimonial-wrapper {
        padding: 0 50px 110px;

        .slider-nav {
            .swiper-button-prev {
                left: 0;
            }

            .swiper-button-next {
                right: 10px;
            }
        }

        .shape-shadow {
            bottom: 80px;
        }
    }

    #testimonial {
        .testimonial {
            .testimonial-content {
                p {
                    font-size: 18px;
                    line-height: 34px;
                }
            }
        }

    }

    .testimonial-two {
        .testi-content-inner {
            padding: 40px 30px;
        }

        .shape-shadow {
            bottom: -30px;
        }
    }
}

@media (max-width: 768px) {

    .testimonials {
        padding: 76px 0 30px;
    }

    .testimonials-three {
        padding: 76px 0 30px;
    }

    #testimonial-wrapper-three .swiper-container {
        padding: 0 25px 50px;
    }

    #testimonial {
        .testimonial {
            padding: 40px;

            .testimonial-content p {
                font-size: 16px;
                line-height: 30px;
            }
        }
    }

    .testimonial-wrapper {
        padding: 0 50px 70px;

        .shape-shadow {
            bottom: 50px;
        }
    }

    #testimonial-wrapper {
        .slider-nav {
            .swiper-button-prev {
                left: -30px;
            }

            .swiper-button-next {
                right: -20px;
            }
        }
    }

    .testimonials-two .animate-shape {
        right: -100%;
    }

    .testimonial-three {
        display: block;
        padding: 30px;

        .avatar {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            margin: auto 30px;
            overflow: hidden;

            img {
                border-radius: 50%;
            }
        }
    }

    #testimonial-wrapper-three {
        .slider-nav {
            bottom: 50px;
            transform: translateX(-50%);
            display: none;
        }

        .quote {
            bottom: 50px;
        }
    }

}

@media (max-width: 576px) {
    #testimonial-wrapper, .testimonial-wrapper {
        .slider-nav {
            .swiper-button-prev,
            .swiper-button-next {
                display: none;
            }
        }
    }

    .testimonial-wrapper {
        padding: 0 0 70px;
    }

    #testimonial .testimonial {
        padding: 40px 25px;
    }

    #testimonial-wrapper-three {
        .slider-nav {
            display: none;

        }

        .swiper-container {
            padding: 0 20px 50px !important;
        }

        .quote {
            right: 30px;
        }
    }

  

    .testimonial-three {
        padding: 30px 10px;
    }
}