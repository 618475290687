.about {
    padding-top: 120px;


}

.about-content {
    padding-right: 130px;

    .section-title {
        margin-bottom: 35px;
    }

    p {
        margin-bottom: 40px;
    }

    .singiture {
        h4 {
            font-size: 20px;
            margin-bottom: 25px;
        }
    }
}

@media (max-width: 991px) {
    .about {
        padding-top: 76px;
    }
    .about-content {
        padding-right: 0;
    }

    .about-thumb {
        margin-top: 30px;
    }
}
