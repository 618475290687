@keyframes zoom {
    from {
        -webkit-transform: scale(1) translate(0px);
      }
      to {
        -webkit-transform: scale(1.03) translate(0px);
      }
}

@keyframes zoommd {
    0% {
        transform: scale(0.9);
      }
      100% {
        transform: scale(1.5);
      }
}

@keyframes bounce {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(40px);
    }
  }

@keyframes movexy {
    0% {
        -webkit-transform: translateX(-10px) scale(0.9);
        transform: translateX(-10px) scale(0.9);
      }
      100% {
        -webkit-transform: translateX(30px) scale(1.3) translateY(10px);
        transform: translateX(30px) scale(1.3) translateY(10px);
      }
}

@keyframes wave {
	0% {

		transform: rotateZ(0deg) translate3d(0,1%,0) rotateZ(0deg);
	}
	100% {
		transform: rotateZ(360deg) translate3d(0,1%,0) rotateZ(-360deg);
	}
}

@keyframes pixFade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


@keyframes pixFadeUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes pixFadeDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes pixFadeLeft {
    0% {
        opacity: 0;
        transform: translateX(20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes pixFadeRight {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes pixZoomIn {
    0% {
        opacity: 0;
        transform: scale(0.7);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes pixBounceIn {
    0% {
        transform: scale(0.1);
        opacity: 0;
    }

    60% {
        transform: scale(1.1);
        opacity: 1;
    }

    100% {
        transform: scale(1);
    }
}

.pixFade {
    animation-name: pixFade;
}

.pixFadeUp {
    animation-name: pixFadeUp;
}

.pixFadeDown {
    animation-name: pixFadeDown;
}

.pixFadeLeft {
    animation-name: pixFadeLeft;
}

.pixFadeRight {
    animation-name: pixFadeRight;
}

.pixZoomIn {
    animation-name: pixZoomIn;
}

.pixBounceIn {
    animation-name: pixBounceIn;
}

@keyframes jumping {
    0% {
       transform: translateY(0);
       opacity: 0;
    }
    50% {
       transform: translateY(-20px);
       opacity: 1;
    }

    100% {
      transform: translateY(0);
      opacity: 0;
    }
}

@keyframes jump {
    0% {
       transform: translateY(5px);

    }
    50% {
       transform: translateY(-10px);

    }

    100% {
      transform: translateY(5px);

    }
}

@keyframes stickySlideDown {
    from {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

// @keyframes moveclouds {
//     0% {
//         margin-left: 1000px;
//     }
// 	100% {
//         margin-left: -1000px;
//     }
// }

@keyframes moveclouds {
    0% {
        transform: translate3d(-10px, -10px, 0);
		opacity: 1;
    }
    50% {
        transform: translate3d(-20px, 20px, 0);
		opacity: 1;
    }
    100% {
        transform: translate3d(30px, 0px, 0);
		opacity: 1;
    }
}


@keyframes pulse {
	0% {
		width: 82px;
		height: 82px;
		opacity: 1;
	}

	50% {
		opacity: 1;
	}

	100% {
		height: 150px;
		width: 150px;
		opacity: 0;
	}
}