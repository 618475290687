.section-title {
    margin-bottom: 70px;

    .sub-title {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 500;
        color: $color__theme;
        letter-spacing: 3px;
    }

    .title {
        font-size: 36px;
        font-weight: 600;
        line-height: 1.4;

        span {
            color: $color__theme
        }
    }

    .title-two {
        font-size: 30px;
        font-weight: 500;
        line-height: 44px;
        max-width: 930px;
        margin: 0 auto;

        span {
            color: $color__theme;
        }
    }

    &.style-two {
        .title {
            font-weight: 500;
        }

        p {
            font-size: 18px;
            line-height: 30px;
            margin-top: 28px;
        }
    }

    &.dark-title {

        .sub-title,
        .title {
            color: #fff;
        }
    }

    &.color-two {
        .sub-title {
            color: $color__theme-2;
        }
    }

    &.style-three {
        span {
            font-weight: 300;
            color: $color__primary;
        }
    }
}

@media (max-width: 991px) {
    .section-title {
        margin-bottom: 50px;

        .title {
            font-size: 32px;
        }
    }

    .section-title {
        &.style-two {


            p {
                margin-top: 13px;

                br {
                    display: none;
                }
            }
        }

        .title-two {
            font-size: 26px;
            line-height: 36px;
        }
    }
}

@media (max-width: 768px) {
    .section-title {

        .title {
            font-size: 28px;

            br {
                display: none;
            }
        }
    }
}

